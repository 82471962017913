<template>
  <div class="login-wrapper">
    <div class="login-content">
      <div class="login-logo">
        <logo/>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "../libs/Logo";
export default {
  name: 'LoginByToken',
  components: {
    Logo
  },
  mounted() {
    let token = this.$route.params.token

    // Login by token
    this.$store.dispatch('auth.logout').then(() => {
      this.$store.commit('auth.set.refresh_token', token)
      this.$router.push({name: 'main'})
    })
  }
}
</script>

<style lang="less" scoped>
.main-text {
  font-weight: bolder;
  text-align: center;
  font-size: 21px;
}
</style>